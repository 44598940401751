
import { Component, Vue, Prop, Watch, Emit } from "vue-property-decorator"
import Previewable from "@/components/layout/Previewable.vue"

import {
  UploadFile,
  GeneratePresignedUrlMutation,
  GeneratePresignedUrlMutationMutation,
  DeleteUploadFilePayload,
  DeleteUploadFileMutation,
} from "@/gql"

export type UploadFileInput = {
  name: string
  extension: string
  key: string
  category: string
  public: boolean
}

@Component({
  components: {
    Previewable,
  },
})
// TODO: multiple support
export default class FileUpload extends Vue {
  @Prop() readonly value?: UploadFile

  @Prop() readonly name?: string

  @Prop() readonly folder?: string

  @Prop({ default: false }) readonly public?: boolean

  @Prop() readonly accept?: string

  @Prop({ required: true }) readonly category!: string

  @Prop() readonly id?: string

  @Prop() readonly mini?: boolean

  @Prop() readonly url?: string

  @Prop() readonly required?: boolean

  @Prop() readonly disabled?: boolean
  @Prop({ default: 0 }) readonly fileNameLength?: number
  @Prop()
  readonly uploadAllowed?: (file: File) => boolean

  @Prop() readonly hideInput?: boolean

  @Prop({ default: true }) readonly showSelectedFile?: boolean

  files: File[] | null = null
  showPreviewable = false
  isUploading = false
  dataFilename: string | null = null
  dataURL: string | ArrayBuffer | null = null
  presignedUrl: string | null = null
  objectKey: string | null = null
  deletingFile = false

  get accepts() {
    return this.accept || ".xlsx, .xls, image/*, .doc, .docx, .ppt, .pptx, .txt, .pdf"
  }

  get filename() {
    return this.name || this.dataFilename
  }

  get isPublic() {
    return this.public !== undefined && this.public !== false
  }

  get extension(): string | null | undefined {
    if (this.value) return this.value.extension

    return this.dataFilename
      ? this.dataFilename.slice(((this.dataFilename.lastIndexOf(".") - 1) >>> 0) + 2)
      : undefined
  }

  async onChange(files: File[]) {
    this.$emit("onFileChange", files)
    if (files.length === 0) {
      this.dataFilename = null
    } else {
      const file = files.constructor.name == "Array" ? files[0] : files
      this.dataFilename = (file as File).name

      if (this.uploadAllowed && !this.uploadAllowed(file as File)) {
        this.clearSelection()
        return this.$emit("error", file)
      }

      // Request presigned url from backend
      const result = await this.getPresignedUrl()

      if (result) {
        this.objectKey = result.key
        // Upload to s3
        await this.uploadFile(result.url, file as File)
      }
    }
  }

  @Emit("change")
  @Emit("input")
  clearSelection() {
    this.files = []
    this.onDeleteFile()
    return null
  }

  async getPresignedUrl() {
    try {
      this.isUploading = true
      this.$emit("upload")

      const result = await this.$apollo.mutate<GeneratePresignedUrlMutationMutation>({
        mutation: GeneratePresignedUrlMutation,
        variables: {
          folder: this.folder,
          filename: `${this.filename}.${this.extension}`,
          public: this.isPublic,
        },
      })

      if (result && result.data) {
        const error = result.data.generatePresignedUrl.error

        if (error) this.addMutationError(error)
        else return result.data.generatePresignedUrl.presignedUrl
      }
    } catch (e) {
      this.addGraphQLError(e as Error)
    } finally {
      this.isUploading = false
    }
  }

  async uploadFile(url: string, file: File) {
    // Upload to S3

    try {
      this.isUploading = true
      const response = await fetch(url, {
        method: "PUT",
        body: file,
      })

      response && this.uploadComplete(response)
    } catch (e) {
      this.addError("Network error: File upload failed")
    } finally {
      this.isUploading = false
    }
  }

  async onDeleteFile() {
    if (!this.id) return
    const result = await this.mutate<DeleteUploadFilePayload>({
      mutation: DeleteUploadFileMutation,
      variables: {
        id: Number(this.id),
      },
      done: () => {
        this.deletingFile = false
      },
    })

    if (result.data && !result.data.error) {
      this.addSuccess("Uploaded File deleted successfully")
    }
  }

  @Emit("change")
  @Emit("input")
  uploadComplete(response: Response) {
    const objectKey = this.objectKey

    this.presignedUrl = null
    this.objectKey = null

    if (response.ok) {
      const uploadFileInput: UploadFileInput = {
        name: this.filename!,
        extension: this.extension!,
        key: objectKey!,
        public: this.isPublic,
        category: this.category,
      }

      return uploadFileInput
    } else {
      this.addError("File upload failed")
    }

    return null
  }

  get attrs() {
    if (Object.prototype.hasOwnProperty.call(this.$attrs, "outlined")) {
      const { label, ...attrs } = this.$attrs
      return attrs
    } else return this.$attrs
  }

  openFile() {
    this.showPreviewable = true
  }

  @Watch("value")
  onFileChange() {
    if (!this.value) {
      this.clearSelection()
    }
  }
}
